@import "normalize";
@import "variables";

%zoomin {
	  div {
		@for $i from 1 through 10 {

	        &:nth-child(#{$i}) {
	            transform: scale($i, $i);
	            transition: all $i * .5s;
	        }
	    } 
	}
}

%zoomout {
	@for $i from 1 through 10 {
        &:nth-child(#{$i}) {
        	background: mix($bands-color1, $bands-color3, nth($percentages, $i));
            transform: scale(0.1 * $i, 0.1 * $i);
            transition: all .5s;
            z-index: 10 - $i;
        }
    }

}


.bunko {
	overflow: hidden;

	.boxes {
		overflow: hidden;

		div {
			display: block;
			background: gray;
			position: absolute;
			width: 100%;
			height: 100vh;
		}

		&.one {

			&.image {
				div {
					&:first-child {
						background: url('../img/ferns.png');
						background-size: cover;
					}
				}
			}
		}

		&.two {

			&.image {

				div {
					&:first-child {
						background: url('../img/boston_botanical.png');
						background-size: cover;
					}
				}

			}
		}
	}
}


